@import "colors";
@import 'boilerplate';

@import "~bootstrap/scss/bootstrap";

$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,300;0,500;1,300;1,500&display=swap');

$mobile-break-point-x: 576px;
$mobile-break-point-y: 640px;

@font-face {
  font-family: Comfortaa;
  font-weight: bold;
  src: url("../fonts/comfortaa-bold.woff2")
}

@font-face {
  font-family: Comfortaa;
  font-weight: normal;
  src: url("../fonts/comfortaa-regular.woff2")
}

@mixin tg-contour($w: 10px) {
  border-top: $w solid $tg-contour;
  border-bottom: $w solid $tg-contour;
}

body {
  overflow-x: hidden;
  overflow-y: auto;

  height: 100vh;
  width: 100vw;

  color: $tg-contour;

  font-family: 'Sarabun', sans-serif;
  font-size: 16pt;

  @media (max-width: $mobile-break-point-x) {
    font-size: 10pt;
  }
}

.text-truegeex {
  font-family: Comfortaa;
}

.text-truegeex-bold {
  @extend .text-truegeex;
  font-weight: bold;
}

.footer {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: $tg-contour;

  .small-font {
    font-size: 0.8em;
  }

  a {
    color: $tg-contour;
  }
}

.page {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
}

.promo-img {
  position: absolute;
  height: 100%;
  z-index: 110;
  top: 0;

  img {
    height: 100%;
  }

  @media (max-width: $mobile-break-point-x) {
    display: none;
  }
}

#page-1 {
  place-items: center;
  background-color: $tg-bg-two;
  .promo-title {
    background-color: $tg-primary;
    color: $tg-primary-light;

    transform-origin: top right;

    .main-title {
      color: $tg-secondary;
    }
  }

  .promo-img {
    right: 75%;
  }

  .page-1-nav {
    width: 100%;
    padding: 5px;

    .arrow-down {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      position: relative;
      margin: 0 1rem;

      span {
        top: .5rem;
        position: absolute;
        width: .75rem;
        height: .1rem;
        background-color: $tg-bg-one;
        display: inline-block;
        transition: all .2s ease;

        &:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }
        &:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }
      }

      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-7;
      animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    }
  }
}

#page-2 {
  background-color: $tg-bg-one;
  .promo-title {
    background-color: $tg-secondary;
    color: $tg-primary-light;

    transform-origin: top left;

    .main-title {
      color: $tg-primary;
    }
  }

  .promo-img {
    left: 70%;
  }
}

#page-3 {
  @extend #page-1;
}

#page-4 {
  @extend #page-2;
}

#page-legal {
  @extend #page-2;

  .promo-title {
    transform-origin: top right;
    transform: translateY(100px) rotate(-4deg);
  }
}

.legal {
  .legal-title {
    font-weight: bold;
  }
  li {
    margin-bottom: 5px;
  }
}

.logo {
  img {
    width: 400px;
    max-width: 90%;
    height: auto;
  }
}

.promo {
  @extend .center-content;
  z-index: 80;

  ul {
    list-style: none;
    padding-left: 1.2em;
  }

  li {
    margin-bottom: 0.2em;
  }

  li::before {
    @extend .fas;
    @extend .fa-angle-right;
    margin-right: 0.4em;
    margin-left: -1.2em;
    width: 0.8em;
  }

  .promo-body {
    max-width: 40%;
    @media (max-width: $mobile-break-point-x) {
      max-width: 80%;
    }
    margin-top: 140px;
  }

  .promo-logo {
    padding: 15px;
    height: 130px;
    text-align: center;

    @media (max-width: $mobile-break-point-x) {
      padding: 5px;
      height: 50px;
    }

    img {
      height: 100%;
    }
  }
}

.promo-title {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 5px;
  width: 106%;
  margin: 0 -3% 0;
  @include tg-contour;

  h2 {
    font-family: 'Passion One';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    white-space: nowrap;
    padding: 0;
    margin: 0 -200%; // negative margin for centered text with overflow

    @extend .text-truegeex;
  }
}

img.greyscale {
  filter: grayscale(100%);
}

.center-content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, .9) translateY(0);
  }
  30% {
    transform: scale(.9, 1.1) translateY(-5px);
  }
  50% {
    transform: scale(1.05, .95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-1px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
